import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import CookieConsent from "react-cookie-consent";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Gallery data={landingPageData.Gallery} />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />
      <CookieConsent
        location="bottom"
        buttonText="OK"
        cookieName="dotnetCookies"
        style={{ background: "#2B373B", opacity: "0.8"}}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={30}
      >
        This website uses strictly necessary cookies for functionality purposes.{" "}
        <span> See our <a href="./privacy-policy.html" target="_blank" rel="noreferrer">privacy policy <i class="fa fa-external-link" aria-hidden="true"></i></a></span>
      </CookieConsent>
    </div>
  );
};

export default App;
